.community-checklist {
  .progress-bar {
    background: linear-gradient(to right, var(--bgColor-attention-emphasis, var(--color-attention-emphasis)), #c5e300, var(--bgColor-success-emphasis, var(--color-success-emphasis)));
    background-color: transparent;
  }

  .progress {
    float: right;
    background-color: var(--bgColor-muted, var(--color-canvas-subtle));
  }

  .checklist-dot {
    color: var(--fgColor-attention, var(--color-attention-fg));
  }
}

.CommunityTemplate-markdown {
  height: 800px;
  overflow-y: scroll;
  font-size: $h5-size;
}

.CommunityTemplate-highlight {
  // stylelint-disable-next-line primer/spacing
  padding: 2px var(--base-size-4);
  margin: 0;
  font-family: $mono-font;
  font-size: $h6-size;
  font-style: normal;
  font-weight: $font-weight-bold;
  color: var(--fgColor-default, var(--color-fg-default));
  cursor: pointer;
  background-color: var(--bgColor-attention-emphasis, var(--color-attention-emphasis));
  border-radius: var(--borderRadius-small);
}

.CommunityTemplate-highlight--focus {
  color: var(--fgColor-onEmphasis, var(--color-fg-on-emphasis));
  background-color: var(--bgColor-accent-emphasis, var(--color-accent-emphasis));
}
